<script>
import InfoCardForm from "@/views/menu/info-card-page/info-card/infoCardForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    infoCardPageService: "infoCardPageService",
    infoBoxService: "infoBoxService",
    toastService: "toastService"
  }
})
export default class InfoCardEdit extends mixins(InfoCardForm) {
  formId = "edit-info-card-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.infoCardPageService.updateCard(this.$route.params.idInfoCardPage, this.model.id, this.model);
      this.$router.push(`/info-card-pages/${this.$route.params.idInfoCardPage}/cards/list`);
      this.toastService.success(this.translations.success.infoCard_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.infoCards_edit;
  }

  async afterCreate () {
    this.infoCardPage = await this.infoCardPageService.read(this.$route.params.idInfoCardPage);
    this.model = await this.infoCardPageService.readCard(this.$route.params.idInfoCardPage, this.$route.params.id);

    if (this.model.idInfoBox !== null) {
      this.model.infoBoxData = await this.infoBoxService.read(this.model.idInfoBox);
      this.model.infoBoxData.enableInfoBox = this.model.infoBoxData.isEnabled;
    } else {
      this.model.infoBoxData = {
        enableInfoBox: false,
        code: "",
        width: null,
        height: null,
        x: null,
        y: null,
        localizations: [],
      };
    }

    this.isReady = true;
  }
}
</script>
